<template>
  <drying-template
    :drying-skin="dryingSkin"
    :dry-open-pores="dryOpenPores"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DryingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/drying/DryingTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { requiredArrayField, field } = fieldBuilder;

const fieldsToReset = ['skinAppearanceDiscomfort'];

const FIELDS = [
  ...fieldsToReset.map(field),
  requiredArrayField('dryingSkin'),
  requiredArrayField('dryOpenPores')
];

export default {
  name: 'Drying',
  components: {
    DryingTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    dryingSkin(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      if (!oldValue && this.dryOpenPores) {
        this.showNextStep();
      } else {
        this.scrollTo('#dry-open-pores');
      }
    },
    dryOpenPores(newValue, oldValue) {
      if (!oldValue && this.dryingSkin) {
        this.showNextStep();
      }
    }
  }
};
</script>
