<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['dryingSkin'])">
      <single-answer-question
        title="label.dryingSkin"
        :value="dryingSkin"
        :options="$options.drySkinOptions"
        hint="info.dryingSkin"
        dysfunction="skin-appearance"
        @input="onFieldChange('dryingSkin', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['dryOpenPores'])">
      <single-answer-question
        id="dry-open-pores"
        title="label.dryOpenPores"
        :value="dryOpenPores"
        :options="$options.dryOpenPoresOptions"
        hint="info.dryOpenPores"
        dysfunction="skin-appearance"
        yes-no
        @input="onFieldChange('dryOpenPores', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap.vue';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion.vue';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  YES_NO_RESPONSE_OPTIONS,
  AMOUNT_OPTIONS
} from '@/modules/questionnaire/constants/questions';

export default {
  name: 'DryingTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  drySkinOptions: AMOUNT_OPTIONS,
  dryOpenPoresOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    dryingSkin: {
      type: String,
      default: ''
    },
    dryOpenPores: {
      type: String,
      default: ''
    }
  }
};
</script>
